"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SolitaireCtrl = void 0;
const ng = window.angular;
//@ts-ignore
const solitaire_1 = require("@games/solitaire/src/lib/solitaire");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const utils_1 = require("@src/shared/utils");
const defaultTurn = 3;
const turnInitial = function (value) {
    let numValue = parseInt(value || defaultTurn) || defaultTurn;
    if ([1, 3].indexOf(numValue) < 0) {
        numValue = defaultTurn;
    }
    return numValue;
};
class SolitaireCtrl {
    constructor($scope, $location, $timeout, $q, ModalServiceFactory, ConfigService, SoundService) {
        var _a;
        this.$scope = $scope;
        this.$location = $location;
        this.$timeout = $timeout;
        this.$q = $q;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.turn = turnInitial(this.$location.search()['turn']);
        this.gameControl = (0, solitaire_1.solitairInitial)(JSON.parse(localStorage.getItem(`${this.constructor.name}_game`) || 'null'), this.gameFinishCallback.bind(this), this.turn);
        this._startTimer = new rxjs_1.Subject();
        this._stopTimer = new rxjs_1.Subject();
        this.startWith = parseInt(localStorage.getItem(`${this.constructor.name}_startWith`) || '0');
        this.timeRemaining = SolitaireCtrl._formatTime(this.startWith);
        this.visible$ = (0, rxjs_1.fromEvent)(document, 'visibilitychange').pipe((0, operators_1.startWith)('visible'), (0, operators_1.map)(() => {
            if (document.visibilityState != 'visible') {
                this.pauseGame();
            }
            return document.visibilityState;
        }));
        this._resume = new rxjs_1.BehaviorSubject('visible');
        this.timerWork = false;
        this.gameFinish = false;
        this.stateHistory = [];
        (_a = this.ConfigService.logoLink$) === null || _a === void 0 ? void 0 : _a.pipe((0, operators_1.tap)((currentTarget) => {
            const e = new MouseEvent('click', { bubbles: true, cancelable: false });
            if (!localStorage.getItem(`${this.constructor.name}_game`)) {
                // event.target?.dispatchEvent(e)
                window.location.href = currentTarget.href;
            }
            else {
                this._confirmNewGame((result) => {
                    if (result) {
                        localStorage.removeItem(`${this.constructor.name}_game`);
                        localStorage.removeItem(`${this.constructor.name}_startWith`);
                        window.location.href = currentTarget.href;
                    }
                });
            }
        })).subscribe();
    }
    $onInit() {
        this._startTimer.pipe((0, operators_1.tap)((value) => {
            if (!value) {
                this.timeRemaining = (0, utils_1.formatTimeRemaining)(0);
            }
        }), (0, operators_1.distinctUntilChanged)(), (0, operators_1.switchMap)((value) => {
            if (value) {
                return this._makeClock();
            }
            return rxjs_1.EMPTY;
        })).subscribe();
        this.gameControl.initSolitaire().then(() => {
            this.$scope.$apply(() => {
                this.stateHistory.push(JSON.stringify(this._copyState()));
            });
        });
        this.setParam('turn', this.gameControl.state.turn);
        this.ConfigService.settings$.pipe((0, operators_1.map)((value) => {
            //@ts-ignore
            const { enable_hints, autocomplete, one_click } = value;
            return {
                "enable_hints": enable_hints,
                "autocomplete": autocomplete,
                "one_click": one_click,
            };
        }), (0, operators_1.distinctUntilChanged)((a, b) => JSON.stringify(a) === JSON.stringify(b))).subscribe((value) => {
            console.log(value);
            this.gameControl.state.options = value;
        });
    }
    setTurn(turn) {
        this.newGame(turn);
    }
    gameFinishCallback(win) {
        this.$scope.$apply(() => {
            console.log('gameFinishCallback', this.gameControl, win);
            if (!win) {
                this.gameFinish = false;
                this._startTimer.next(true);
                const stateStr = JSON.stringify(this._copyState());
                this.stateHistory.push(stateStr);
                localStorage.setItem(`${this.constructor.name}_game`, stateStr);
            }
            if ((win === true) || (win === false)) {
                this.endGame(win);
            }
        });
    }
    newGame(turn = undefined) {
        this._confirmNewGame((result) => {
            if (result) {
                // this.level = level || this.level
                // this.version = version || this.version
                // this.setParam('version', this.version)
                // this.setParam('level', this.level)
                localStorage.removeItem(`${this.constructor.name}_game`);
                localStorage.removeItem(`${this.constructor.name}_startWith`);
                // this.bestScore = JSON.parse(localStorage.getItem(`${this.constructor.name}_bestScores`) || '{}')[`${this.level}:${this.version}`]
                // this.score = 0
                this._startTimer.next(null);
                this.startWith = 0;
                this.timeRemaining = '00:00';
                this.gameFinish = false;
                if (turn) {
                    this.setParam('turn', turn);
                    this.gameControl.state.turn = turn;
                }
                this.$timeout(() => {
                    // this.game?.destroy()
                    // this.game = new Game(this.level, this.version, this.frameCallback.bind(this))
                    this.gameControl.resetGame().then(() => {
                        this.stateHistory = [
                            JSON.stringify(this._copyState())
                        ];
                    });
                });
            }
        }).catch(() => {
            // this._startTimer.next(null)
        });
    }
    restartGame() {
        this._confirmNewGame((result) => {
            if (result) {
                const state = JSON.parse(localStorage.getItem(`${this.constructor.name}_game`) || 'null') || this.gameControl.state;
                // this.level = level || this.level
                // this.version = version || this.version
                // this.setParam('version', this.version)
                // this.setParam('level', this.level)
                localStorage.removeItem(`${this.constructor.name}_game`);
                localStorage.removeItem(`${this.constructor.name}_startWith`);
                // this.bestScore = JSON.parse(localStorage.getItem(`${this.constructor.name}_bestScores`) || '{}')[`${this.level}:${this.version}`]
                // this.score = 0
                this._startTimer.next(null);
                this.startWith = 0;
                this.timeRemaining = '00:00';
                this.gameFinish = false;
                this.$timeout(() => {
                    // this.game?.destroy()
                    // this.game = new Game(this.level, this.version, this.frameCallback.bind(this))
                    console.debug(state);
                    if (state === null || state === void 0 ? void 0 : state.startWith) {
                        const startState = Object.assign(Object.assign({}, state.startWith), { options: this.gameControl.state.options, startWith: state.startWith, turn: this.turn, moves_num: 0, score: 0 });
                        const stateStr = JSON.stringify(startState);
                        this.gameControl = (0, solitaire_1.solitairInitial)(startState, this.gameFinishCallback.bind(this));
                        this.gameControl.initSolitaire().then(() => {
                            this.stateHistory = [stateStr];
                        });
                    }
                    else {
                        // this.gameControl.resetGame()
                    }
                });
            }
        });
    }
    pauseGame() {
        if (!this.gameFinish && this.ConfigService.cookieSettings.show_timer) {
            // this.doPause(true)
            this._resume.next('hidden');
            this.ModalServiceFactory.open({
                id: 'paused',
                component: "pause-comp",
                scope: this.$scope,
                strategy: "if_close_all"
            }).then(() => {
                // this.doPause(false)
                this._resume.next('visible');
            });
        }
    }
    showHint() {
        this.gameControl.showHint();
        this._startTimer.next(true);
    }
    doUndo() {
        if (this.stateHistory.length > 1) {
            if (!this.timerWork) {
                this._startTimer.next(null);
                this._startTimer.next(true);
            }
            this.gameFinish = false;
            this.stateHistory.pop();
            const stateStr = this.stateHistory.pop() || 'null';
            // if (!this.lastUndo) {
            //     stateStr = this.stateHistory.pop() || 'null'
            // }
            const options = Object.assign({}, this.gameControl.state.options);
            if (stateStr != 'null') {
                this.gameControl = (0, solitaire_1.solitairInitial)(JSON.parse(stateStr), this.gameFinishCallback.bind(this));
            }
            // файловая поддержка длинны массива отмены, чтобыне лагало отключение кнопки отмены
            this.stateHistory.push('null');
            this.gameControl.initSolitaire().then(() => {
                this.stateHistory[this.stateHistory.length - 1] = JSON.stringify(this._copyState());
                // this.stateHistory.push()
                // if (this.stateHistory.length == 0) {
                //     this.stateHistory = [
                // JSON.stringify(this._copyState())
                // ]
                // }
            });
            this.gameControl.state.options = options;
        }
    }
    setParam(name, value) {
        console.log(name, value);
        if (name == 'turn') {
            if (value == defaultTurn) {
                this.$location.search('turn', null);
                this.turn = defaultTurn;
            }
            else {
                this.$location.search('turn', value);
                this.turn = value;
            }
        }
    }
    endGame(win) {
        this._stopTimer.next(null);
        this.gameFinish = true;
        this.SoundService.play(win ? 'win' : 'lose');
        console.log('win', win);
        this.ModalServiceFactory.open({
            id: 'game_status',
            template: require("./end_game.ng.html"),
            component: "alert-comp",
            scope: this.$scope,
            extraContext: {
                win: win,
                timeRemaining: this.timeRemaining,
                score: this.gameControl.state.score,
                moves_num: this.gameControl.state.moves_num,
                show_any: [
                    this.ConfigService.cookieSettings.show_timer,
                    this.ConfigService.cookieSettings.show_score,
                    this.ConfigService.cookieSettings.show_moves_num,
                ].some((item) => !!item),
                cookieSettings: this.ConfigService.cookieSettings
            }
        }).then((result) => {
            localStorage.removeItem(`${this.constructor.name}_game`);
            localStorage.removeItem(`${this.constructor.name}_startWith`);
            if (result == 'newGame') {
                this.newGame();
                return;
            }
            else if (result == 'undo') {
                this.ModalServiceFactory.close('game_status');
                this.doUndo();
            }
            if (!win)
                this.gameFinish = false;
        });
    }
    _copyState() {
        const state = {
            cards: this.gameControl.state.cards.map((item) => ({
                facingUp: item.facingUp,
                number: item.number,
                type: item.type
            })),
            deal: {
                deal: { cards: [...this.gameControl.state.deal.deal.cards] },
                pile: { cards: [...this.gameControl.state.deal.pile.cards] },
            },
            desk: this.gameControl.state.desk.map((item) => ({ cards: item.cards })),
            finish: this.gameControl.state.finish.map((item) => ({ cards: item.cards })),
            startWith: this.gameControl.state.startWith,
            moves_num: this.gameControl.state.moves_num,
            score: this.gameControl.state.score,
            pileCycle: this.gameControl.state.pileCycle,
            turn: this.turn,
        };
        // console.log('_copyState', state)
        return state;
    }
    _confirmNewGame(callback) {
        if (this.gameFinish || !this.startWith) {
            return this.$q.when().then(callback ? callback(true) : null);
        }
        else {
            // const paused = this.game?.paused || !this.game?.moving
            // this.doPause(true)
            const timerWork = this.timerWork;
            this._stopTimer.next(true);
            return this.ModalServiceFactory.open({
                id: 'solitaire_new_game',
                component: "confirm-comp",
                scope: this.$scope,
                extraContext: {
                    settings: {}
                }
            }).then((result) => {
                if (result) {
                    callback ? callback(result) : null;
                }
                else {
                    if (timerWork) {
                        this._startTimer.next(null);
                        this._startTimer.next(true);
                    }
                    throw { error: 'cancel' };
                }
            });
        }
    }
    _makeClock() {
        return (0, rxjs_1.combineLatest)([this.visible$, this._resume]).pipe((0, operators_1.switchMap)(([v1, v2]) => {
            if ((v1 == 'visible') && (v2 == 'visible')) {
                return (0, rxjs_1.timer)(0, 1000).pipe((0, operators_1.withLatestFrom)((0, rxjs_1.of)(this.startWith)));
            }
            return rxjs_1.EMPTY;
        }), (0, operators_1.map)(([i, startWith]) => {
            const sec = i + startWith;
            this.$timeout(() => {
                this.timerWork = true;
                this.startWith = sec;
                localStorage.setItem(`${this.constructor.name}_startWith`, sec.toString());
                this.timeRemaining = (0, utils_1.formatTimeRemaining)(sec);
            });
            return sec;
        }), (0, operators_1.takeUntil)(this._stopTimer), (0, operators_1.finalize)(() => {
            this.timerWork = false;
        }));
    }
    static _formatTime(sec) {
        if (sec >= 3600) {
            return new Date(sec * 1000).toISOString().substr(11, 8);
        }
        return new Date(sec * 1000).toISOString().substr(14, 5);
    }
}
exports.SolitaireCtrl = SolitaireCtrl;
SolitaireCtrl.$inject = ['$scope', '$location', '$timeout', '$q',
    'ModalServiceFactory', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.component('gameSolitaire', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: SolitaireCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['SoundServiceProvider', 'WsServiceProvider', 'ConfigServiceProvider', (SoundServiceProvider, WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('solitaire/');
        SoundServiceProvider.setSound({
            'win': require('./sounds/win.mp3').default,
            'lose': require('./sounds/lose.mp3').default,
        });
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            sound_effects: true,
            show_timer: true,
            show_score: true,
            show_moves_num: true,
            enable_hints: false,
            autocomplete: true,
            one_click: true,
            right_mode: false,
        });
    }]);
