"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const game_1 = require("@games/solitaire/src/game");
const appModule = ng.module('app');
class KlondikeCtrl extends game_1.SolitaireCtrl {
}
appModule.component('gameKlondike', {
    transclude: true,
    template: require("@games/solitaire/src/game.ng.html"),
    controller: KlondikeCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['SoundServiceProvider', 'WsServiceProvider', 'ConfigServiceProvider', (SoundServiceProvider, WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('klondike/');
        SoundServiceProvider.setSound({
            'win': require('./sounds/win.mp3').default,
            'lose': require('./sounds/lose.mp3').default,
        });
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            sound_effects: true,
            show_timer: true,
            show_score: true,
            show_moves_num: true,
            enable_hints: false,
            autocomplete: true,
            one_click: true,
            right_mode: false,
        });
    }]);
